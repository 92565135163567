body {
    background: #eee url(./../Image/bg.png);
    font-family: 'Saira', sans-serif;
}
 
.logo {
    width: 200px;
    height: 50px;
}

h1 {
    position: relative;
    text-align: center;
    color: #353535;
    font-size: 50px;
    font-family: "Cormorant Garamond", serif;
}

p {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 18px;
    color: #676767;
}

.frame {
    width: 90%;
    margin: 40px auto;
    text-align: center;
}

button {
    margin: 20px;
}

.custom-btn {
    width: 170px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
}


/* 8 */
.btn-8 {
    background-color: #f0ecfc;
    background-image: linear-gradient(315deg, #6fc89c 0%, #5fc4ff 74%);
    line-height: 42px;
    padding: 0;
    border: none;
}

.btn-8 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.btn-8:before,
.btn-8:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: #c797eb;
    /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
    transition: all 0.3s ease;
}

.btn-8:before {
    height: 0%;
    width: 2px;
}

.btn-8:after {
    width: 0%;
    height: 2px;
}

.btn-8:hover:before {
    height: 100%;
}

.btn-8:hover:after {
    width: 100%;
}

.btn-8:hover {
    background: transparent;
}

.btn-8 span:hover {
    color: #9000ff;
}

.btn-8 span:before,
.btn-8 span:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: #c797eb;
    /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
    transition: all 0.3s ease;
}

.btn-8 span:before {
    width: 2px;
    height: 0%;
}

.btn-8 span:after {
    height: 2px;
    width: 0%;
}

.btn-8 span:hover:before {
    height: 100%;
}

.btn-8 span:hover:after {
    width: 100%;
}


@keyframes moveArrow {

    0% {
        transform: translateX(0px);
    }

    49% {
        transform: translateX(50px);
    }

    50% {
        transform: translateX(-50px);
    }

    100% {
        transform: translateX(0px);
    }

}


/* 13 */
.btn-13 {
    background-color: #f700ff;
    background-image: linear-gradient(315deg, #f39393 0%, #b803c8 74%);
    border: none;
    z-index: 1;
}

.btn-13:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: #4dccc6;
    background-image: linear-gradient(315deg, #6b4dcc 0%, #e496a3 74%);
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

.btn-13:hover {
    color: #fff;
}

.btn-13:hover:after {
    top: 0;
    height: 100%;
}

.btn-13:active {
    top: 2px;
}



.bg-menu {
    background-image: url('./../Image/fill.png');
}

.thumb-1 {
    width: 80%;
    height: 150px;
}

.w-70pt {
    width: 70%;
}

.postcard {
    width: 80%;
}

.fix-hr {
    height: 2px;
    background-color: #ffffff;
}

.metab:hover {
    background-color: #ffffff65;
}

.thumb {
    width: 100%;
}

.thea
{
    text-decoration: none!important;

    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

.inputx {
    width: 100%;
    height: 45px;
}

.w-100pt {
    width: 100%;
}

.w-60pt {
    width: 60%;
}

.bg-main {
    background-color: rgba(255, 255, 255, 0.788);
}

.height-img-thumbnail {
    height: 190px;
    overflow: hidden;
}

.text-bold {
    font-weight: bold;
}

.text-hidden {
    max-width: 280px;
}

.no-border {
    border-radius: 0 !important;
}

.btn-buy {
    margin-top: -10px;
}

.img-show {
    width: 100%;
}

.img-show-fix {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.btn-buy {
    height: 46px;
}

.xtext {
    margin-top: -10px;
}

.text-item {
    color: #ff002b;
}

.avatar {
    width: 120px;
    margin-top: -270px;
}

.text-avatar {
    margin-top: -70px;
}

.upcase {
    text-transform: uppercase;
}

.maindanhsach {
    min-height: 500px;
}

.iconload {
    width: 300px;
}

.img-thumb {
    width: 100%;
    height: 200px;
}

.bg-table {
    min-height: 500px;
    background-color: #fff;
    height: 500px;
    overflow: scroll;
}

.cover {
    height: 300px;
    overflow: hidden;
}

.dshow {
    font-size: 15px;
}

.bg-footer {
    background-image: url('./../Image/footer.png');
    background-color: rgba(70, 70, 70, 0.822);
}